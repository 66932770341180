<template>
  <div>
    <NewPointCardCard
    	@closed="closed"
        @created="created"
		v-if="showNewPointCardDialog"
        :pointcard="pc"
    />
    <ExtendedView :tabs="tabs">
      <template v-slot:list="{ item }">
        <PointCardList
          v-if="item.key === 'active'"
          :point-cards="activePointCards"
          :load-items-callback="getPointCards"
          :call-to-actions="callToActions"
          :fixed-filters="activeFilters"
          @selectPointCard="selectPointCard"
        />
        <PointCardList
          v-if="item.key === 'inactive'"
          :point-cards="inactivePointCards"
          :load-items-callback="getPointCards"
          :call-to-actions="callToActions"
          :fixed-filters="inactiveFilters"
          @selectPointCard="selectPointCard"
        />
      </template>
      <template slot="profile">
        <PointCardProfile
          v-if="selectedPointCard !== null"
          :point-card="selectedPointCard"
          @updated="selectedPointCard = null"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>
import ExtendedView from "@/components/common/ExtendedView.vue"
import PointCardList from "@/components/pointCards/PointCardList.vue"
import PointCardProfile from "@/components/pointCards/PointCardProfile.vue"
import NewPointCardCard from "@/components/pointCards/NewPointCardCard.vue"

export default {
	name: "PointCards",
	components: {
		NewPointCardCard,
		PointCardProfile,
		PointCardList,
		ExtendedView
	},
	data() {
		return {
			errorMessage: null,
			selectedPointCard: null,
			showNewPointCardDialog: false,
			pc: {
				active: true,
				amount: 0,
				customer: null,
				expiryMonth: (new Date().getMonth()).toString(),
				expiryYear: (new Date().getFullYear() + 2).toString(),
				paymentMethod: 'pointCard',
				paymentMethodInstance: null,
				remoteID: ""
			},
			activeFilters: [
				{
					key: 'active',
					value: true,
					operator: 'equals',
					type: 'boolean'
				}
			],
			inactiveFilters: [
				{
					key: 'active',
					value: false,
					operator: 'equals',
					type: 'boolean'
				}
			],
			callToActions: [
				{
					title: this.$t('addPointCard'),
					callback: () => this.showNewPointCardDialog = !this.showNewPointCardDialog
				}
			]
		}
	},
	computed: {
		tabs() {
			const tabs = [
				{
					title: this.$t('active'),
					key: 'active',
					badge: {
						color: 'green',
						content: 0
					}
				},
				{
					title: this.$t('inactive'),
					key: 'inactive',
					badge: {
						color: 'red',
						content: 0
					}
				}
			]

			if(this.activePointCards !== null) {
				tabs[0].badge.content = this.activePointCards.length
			}

			if(this.inactivePointCards !== null) {
				tabs[1].badge.content = this.inactivePointCards.length
			}

			return tabs
		},
		activePointCards() {
			if(this.pointCards === null || this.pointCards === undefined) {
				return null
			}

			return this.pointCards.filter(pointCard => {
				return pointCard.active
			})
		},
		inactivePointCards() {
			if(this.pointCards === null || this.pointCards === undefined) {
				return null
			}

			return this.pointCards.filter(pointCard => {
				return !pointCard.active
			})
		},
		pointCards() {
			return this.$store.state.pointCards
		}
	},
	methods: {
		getPointCards() {
			return this.$store.dispatch('getPointCards', {})
		},
		exportPointCards() {
			 this.$store.commit('updateExportObjects', this.pointCards)
		},
		selectPointCard(pointCard) {
			this.selectedPointCard = pointCard
		},
		closed() {
			this.showNewPointCardDialog = !this.showNewPointCardDialog
			this.pc = {
				active: true,
				amount: 0,
				customer: null,
				expiryMonth: (new Date().getMonth()).toString(),
				expiryYear: (new Date().getFullYear() + 2).toString(),
				paymentMethod: 'pointcard',
				paymentMethodInstance: null,
				remoteID: ""
			}
		},
		created() {
			this.$store.commit('updateAppBarTabs', this.tabs)
		}
	},
	watch: {
		pointCards() {
			this.$store.commit('updateAppBarTabs', this.tabs)
		}
	},
	mounted() {
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
	},
	async created() {
		await this.$store.dispatch('getCustomers', {})
		this.$store.commit('updateAppBarExport', {
			callback: this.exportPointCards
		})
	}
}
</script>

<style scoped>

</style>
