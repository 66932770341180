<template>
  <div :key="pointCard.uuid">
    <v-card>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1">
            {{ pointCard.remoteID }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="pointCard.cardName === ''">Point card - {{ amount }} ISK</v-list-item-subtitle>
          <v-list-item-subtitle v-else>{{ pointCard.cardName }} - {{ amount }} ISK</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-row dense>

            <v-col cols="auto">
              <v-btn
                depressed
                color="primary"
                :disabled="editing"
                :loading="editing"
                @click="showQuickActionDialog"
              >
                {{ $t('edit') }}
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn
                v-if="pointCard.active"
                outlined
                color="error"
                @click="disablePointCard"
                :disabled="updating"
                :loading="updating"
              >
                {{ $t('disable') }}
              </v-btn>
              <v-btn
                v-else
                outlined
                color="primary"
                @click="enablePointCard"
                :disabled="updating"
                :loading="updating"
              >
                {{ $t('enable') }}
              </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn
                  text
                  outlined
                  link
                  target="_blank"
                  rel="noopener noreferrer"
                  :href="onlineUrl"
                >
                  {{ $t('viewOnline') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item-action>
      </v-list-item>
      <v-tabs v-model="tab">
        <v-tab href="#preview">
          {{ $t('preview') }}
        </v-tab>
        <v-tab href="#useage">
          {{ $t('usage') }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items
      v-model="tab"
      class="transparent"
    >
      <v-tab-item value="useage">
        <v-row>
          <v-col cols="12">
            <h3>Usage</h3>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="preview">
        <v-row>
          <v-col cols="12">
            <iframe
              :title="$t('preview')"
              height="1050px"
              width="100%"
              seamless
              style="border:none;"
              :src="onlineUrl"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
	name: "PointCardProfile",
	props: {
		pointCard: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			tab: null,
			updating: false,
      editing: false
		}
	},
  computed: {
    amount() {
      if (this.pointCard.amount) {
        return this.pointCard.amount
      }

      return 0
    },
    organization() {
      return this.$store.state.organization
    },
    onlineUrl() {
      const url = 'https://pointcard.salescloud.is?uuid=' + this.pointCard.uuid + '&organization=' + this.organization.uuid

      return url
    },
  },
	methods: {
    editPointCard({ uuid, amount, cardName, expiryMonth, expiryYear }) {
      this.editing = true

      this.$store.dispatch('editPointCard', { pointCardUUID: uuid, amount: parseFloat(amount), cardName: cardName, expiryMonth: expiryMonth, expiryYear: expiryYear }).then((result) => {
        this.$store.commit('updateActionSuccess', {
          message: this.$t('successfullyUpdated')
        })
        this.$emit('updated')
      }).finally(() => {
        this.editing = false
      })

    },
    showQuickActionDialog() {
      this.quickActionDialog = true
      this.$store.commit('updateDataToMutate', {
        objectToMutate: this.pointCard,
        formComponent: 'components/pointCards/EditPointCard',
        saveCallback: this.editPointCard,
        title: this.$t('edit')
      })
    },
    disablePointCard() {
      this.updating = true
      this.$store.dispatch('disablePointCard', this.pointCard.uuid).then(() => {
        this.$store.commit('updateActionSuccess', {
          message: this.$t('successfullyDisabled')
        })
        this.$emit('updated')
      }).finally(() => {
        this.updating = false
      })
    },
    enablePointCard() {
      this.updating = true
      this.$store.dispatch('enablePointCard', this.pointCard.uuid).then(() => {
        this.$store.commit('updateActionSuccess', {
          message: this.$t('successfullyEnabled')
        })
        this.$emit('updated')
      }).finally(() => {
        this.updating = false
      })
    },
	}
}
</script>

<style scoped>

</style>
